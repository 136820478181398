import Image1 from "../../assets/img/portfolio/example01.gif";
import Image2 from "../../assets/img/portfolio/example02.gif";
import Image3 from "../../assets/img/portfolio/example03.gif";


const PortfolioData = [
  {
    id: 1,
    type: "Dishwashers",
    image: Image1,
    tag: ["dishwasher"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "dishwasher",
        client: "Bosh",
        language: "motor replacement, drain system cleaning, water line and water leaking replacement, control board replacement, door lock and door spring replacement, installation",
        preview: "2,5 hours",
        link: "2,5 hours",
      },
    ],
  },
  {
    id: 2,
    type: "refrigerators",
    image: Image2,
    tag: ["refrigerator"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "refrigerator",
        client: "Whirlpool",
        language: "evaporator and condenser fan replacement, water leak fixing, heating problem fixing, maintenance",
        preview: "3 hours",
        link: "3 hours",
      },
    ],
  },
  {
    id: 3,
    type: "washing machines",
    image: Image3,
    tag: ["washing machine"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "washing machines",
        client: "Samsung",
        language: "motor repair, electronic problem, leaking fixing, drain problem fixing, maintenance, installation",
        preview: "1 hour",
        link: "1 hour",
      },
    ],
  },
  {
    id: 4,
    type: "Dryers",
    image: Image3,
    tag: ["dryer"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "electrical dryer",
        client: "LG",
        language: "motor repair, electronic problem, leaking fixing, drain problem fixing, heating problems, spinning problems, maintenance, installation",
        preview: "1 hour",
        link: "1 hour",
      },
    ],
  },
  {
    id: 5,
    type: "electrical Oven",
    image: Image3,
    tag: ["oven"],
    delayAnimation: "5",
    modalDetails: [
      {
        project: "electrical oven",
        client: "LG",
        language: "electronic and electrical problems, heating problems, maintenance, installation",
        preview: "1 hour",
        link: "1 hour",
      },
    ],
  },
];

export default PortfolioData;
